body {
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.qr-image-wrapper section div {
  box-shadow: unset !important;   
  border:50px solid rgba(0,0,0,0.3) !important;
  height:350px !important;  
}

.qr-image-wrapper section video {
  height:350px !important
}

.qr-image-wrapper section img {
  height:350px !important
}

.qr-main{
  width:100%;
  max-width:500px;
  margin-top:50px
  /* height:100vh */
}

.gallery-btn{
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: 50%;
  border:none;
  outline: none;
  background-color: indigo;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  width: 150px;
  height: 40px;
  border-radius: 18px;
  padding: 0px 10px;
}

.detail{
  display:flex;
  flex-direction:column;
  place-items:center;
  height:150px;
  background-color:red
}


@media (max-width: 600px) {
  .qr-main{
    max-width:100vw;
  }

  .detail{
    height: 100%;
  }
}

.PhoneInput{
    
  width: 100%;
  border: 0;
  display: flex;
  outline: 0;
  font-size: 1em;
  border-radius: 4px;
  background-color: white;

}

.PhoneInputInput{
  border: 0 ;
  margin: 10px 0 20px 0;
  display: block;
  outline: 0;
  padding: 10px 14px;
  font-size: 1em;
  width: 85%;
  max-width: 85%;
  min-width: 85%;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 3px 10px, rgba(0, 0, 0, 0.0196078) 0px 3px 5px;
  border-radius: 4px;
  background-color: white;
}

#recaptcha-container{
  display: none;
}

@font-face {
  font-family: 'kamran';
  src: url('./assets/fonts/kamran-normal.ttf') format('truetype');
}

@font-face {
  font-family: 'kamran-bold';
  src: url('./assets/fonts/kamran-bold.ttf') format('truetype');
}